import React from 'react';
import Web3 from 'web3';



const WithdrawLoading = (props) => {
  return (

      <div className="row justify-content-center">
          <div className="col-1 justify-content-right pr-0">
              <div className="shape-img4 pl-5">
                  <img src={require("shape/shape1.svg")} alt="image" />

              </div>
          </div>
          <div className="col-2 pl-2">
              <p>Loading...</p>
          </div>
    </div>
  )
}

class Migrationsv1tov2 extends React.Component {



    async weStopSale() {

        console.log("<-- INSIDE STOP SALE--> ")
        console.log("this.props.account --> ",this.props.account)

        if(this.props.contract!=='undefined'){
          try{

            await this.props.contract.methods.pauseSale().send({from: this.props.account})

          } catch(e) {
            console.log('Error, withdraw: ', e)
          }
        }
      }

      async weRestartSale() {

          console.log("<-- INSIDE RESTART SALE--> ")
          console.log("this.props.account --> ",this.props.account)

          if(this.props.contract!=='undefined'){
            try{

              await this.props.contract.methods.restartSale().send({from: this.props.account})

            } catch(e) {
              console.log('Error, withdraw: ', e)
            }
          }
        }


    async weMintpresale() {

        console.log("<-- INSIDE MINTING presale--> ")
        console.log("this.props.account --> ",this.props.account)

        if(this.props.contract!=='undefined'){
          try{

            await this.props.contract.methods.reserveGiveaway().send({from: this.props.account})

          } catch(e) {
            console.log('Error, withdraw: ', e)
          }
        }
      }



    async weMigrate(mto, maddress) {

        console.log("<-- INSIDE MIGRATIONS--> ")
        console.log("this.props.account --> ",this.props.account)

        if(this.props.contract!=='undefined'){
          try{

            await this.props.contract.methods.MigrateGeneral(mto,maddress).send({from: this.props.account})

          } catch(e) {
            console.log('Error, withdraw: ', e)
          }
        }
      }


      async weChangePrice(mto) {

          console.log("<-- INSIDE MIGRATIONS--> ")
          console.log("this.props.account --> ",this.props.account)

          if(this.props.contract!=='undefined'){
            try{

              await this.props.contract.methods.setPrice(mto).send({from: this.props.account})

            } catch(e) {
              console.log('Error, withdraw: ', e)
            }
          }
        }



    constructor(props) {
       super(props);
       this.state = {
           isToggleOn: true,
            action: "wait"

       };

       // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
     }


render() {
    return (
        <section className="what-we-do-area bg-fafafb pt-5 pb-5">

            <div className="container ">
            { this.props.ownerAccount != null ?
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">



                                    <form onSubmit={(e) => {
                                        e.preventDefault()    //doesnt let the page refresh

                                        this.weStopSale()


                                        }}>

                                        <input
                                          type='text'
                                          className="form-control mb1"
                                          placeholder='Nothing to write'
                                          ref={(input) => {this.msize = input }}
                                           />

                                        <input type='submit' className='btn btn-block btn-primary' value ='STOP SALE'  />

                                        </form>




                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh

                                            this.weRestartSale()


                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='Nothing to write'
                                              ref={(input) => {this.msize = input }}
                                               />

                                            <input type='submit' className='btn btn-block btn-primary' value ='RESTART SALE'  />

                                            </form>



                                            <form onSubmit={(e) => {
                                                e.preventDefault()    //doesnt let the page refresh
                                                const tkprice = this.mprice.value.toString()

                                                console.log(" trying to change price to : ",tkprice)

                                                this.weChangePrice(Web3.utils.toWei(tkprice, 'ether'))

                                                }}>


                                                <input
                                                  type='text'
                                                  className="form-control mb1"
                                                  placeholder='0.05'
                                                  ref={(input) => {this.mprice = input }}
                                                   />

                                                <input type='submit' className='btn btn-block btn-primary' value ='Change Price of Token - example 0.05ETH'  />

                                            </form>



                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh

                                            this.weMintpresale()


                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='Nothing to write'
                                              ref={(input) => {this.msize = input }}
                                               />

                                            <input type='submit' className='btn btn-block btn-primary' value ='Mint 15 NFTs to Owner - presale'  />

                                            </form>




                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh

                                            const tknumber = this.msize.value
                                            const tkaddress = this.maddress.value

                                            this.weMigrate(tkaddress,tknumber)


                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='Destination address'
                                              ref={(input) => {this.maddress = input }}
                                               />


                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='size - 20 max'
                                              ref={(input) => {this.msize = input }}
                                               />

                                            <input type='submit' className='btn btn-block btn-primary' value ='Migrate General'  />

                                            </form>



                                        <hr/>
                                        </div>

                            </div>

                </div>
                </div>

                : <WithdrawLoading/> }

            </div>


        </section>
    )
}
}

export default Migrationsv1tov2;
